import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import parse from 'html-react-parser';
import styles from './newsletter.module.scss';

export default function Newsletter() {
  const [email, setEmail] = useState(null);
  const [mailchimpResult, setMailchimpResult] = useState(null);

  const handleInputChange = e => {
    setEmail(e.target.value);
  };

  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 2. via `async/await`
  const handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(email);
    // I recommend setting `result` to React state
    // but you can do whatever you want
    setMailchimpResult(result);
  };

  // console.log(mailchimpResult);

  const idSuffix =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15);

  return !!mailchimpResult?.msg ? (
    <div className={styles.container}>
      <p className={styles.msg}>{parse(mailchimpResult.msg)}</p>
    </div>
  ) : (
    <form onSubmit={handleSubmit} className={styles.container}>
      <label className={styles.label} htmlFor={`mc-${idSuffix}`}>
        newsletter signup
      </label>
      <div className={styles.row}>
        <input
          id={`mc-${idSuffix}`}
          type="email"
          placeholder="enter email"
          className={styles.input}
          onChange={handleInputChange}
        />
        <button className={styles.button}>go</button>
      </div>
    </form>
  );
}
