import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Header, Footer } from '~/components';
import cc from 'classcat';
import '~/styles/app.scss';

export default function Layout({ children, pathContext, ...restProps }) {
  const [isHome, setIsHome] = useState(pathContext.uri === '/');

  useEffect(() => {
    document.body.classList.add('go');
  }, []);

  useEffect(() => {
    setIsHome(pathContext.uri === '/');
  }, [pathContext.uri]);

  const pageTitle =
    pathContext?.title?.toLowerCase() === 'home'
      ? null
      : pathContext?.title?.toLowerCase();

  return (
    <div className={cc(isHome && 'home')}>
      <Helmet
        title={pageTitle ? `konjo | ${pageTitle}` : 'konjo'}
        meta={[
          {
            name: 'description',
            content: 'yoga that is trauma informed & youth focused',
          },
        ]}
      >
        <html lang="en" />
        {/* calendly stuff */}
        <link
          rel="preload"
          as="style"
          href="https://calendly.com/assets/external/widget.css"
          onload="this.onload=null;this.rel='stylesheet'"
        />
        <noscript>
          {`<link
            rel="stylesheet"
            href="https://calendly.com/assets/external/widget.css"
          />`}
        </noscript>
        <script
          src="https://calendly.com/assets/external/widget.js"
          type="text/javascript"
          defer
        />
      </Helmet>
      <Header path={restProps.path} />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
