import React from 'react';
import Image from 'gatsby-image';
import Nuka from 'nuka-carousel';
import styles from './carousel.module.scss';

export default function Carousel(props) {
  const { media } = props;

  if (props.attrs?.items?.length > 1)
    return (
      <Nuka
        style={{ height: '100vh' }}
        className={styles.carousel}
        autoplay={true}
        autoplayInterval={4000}
        speed={800}
        pauseOnHover={false}
        withoutControls
        swiping={false}
        dragging={false}
        transitionMode="fade"
        // loop={true}
        wrapAround={true}
        autoGenerateStyleTag={false}
      >
        {props.attrs?.items?.map(item => {
          const image = media?.find(n => n.id === item.id * 1);

          if (image) {
            return (
              <Image
                key={`${item.id}`}
                className={styles.image}
                fluid={image.localFile.childImageSharp.fluid}
              />
            );
          } else {
            return (
              <img
                key={`${item.id}`}
                className={styles.image}
                src={item.url}
                loading="lazy"
                alt=""
              />
            );
          }
        })}
      </Nuka>
    );

  const [item] = props.attrs?.items;
  const image = media?.find(n => n.id === item.id * 1);

  return (
    <div className={styles.carousel} style={{ height: '100vh' }}>
      {image ? (
        <Image
          key={`${item.id}`}
          className={styles.image}
          fluid={image.localFile.childImageSharp.fluid}
        />
      ) : (
        <img
          key={`${item.id}`}
          className={styles.image}
          src={item.url}
          loading="lazy"
          alt=""
        />
      )}
    </div>
  );
}

// underline menu active & hover & link text
