import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import cc from 'classcat';
import Portal from './Portal';
import { Newsletter } from '~/components';
import styles from './header.module.scss';

export default function Header({ path }) {
  const { mainMenu } = useStaticQuery(MENU_QUERY);

  const [isOpen, setIsOpen] = useState(false);
  const [colors, setColors] = useState({});

  useEffect(() => {
    document.body.classList.toggle('lock', isOpen);
    return () => document.body.classList.remove('lock');
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [path]);

  const handleMenuButtonClick = () => setIsOpen(!isOpen);
  const handleMouseEnter = colors => setColors(colors);
  const handleMouseLeave = () => setColors({});

  return (
    <>
      <header className={cc([styles.header, isOpen && styles.isOpen])}>
        <Link to="/" className={styles.logo}>
          Konjo
        </Link>
        <button className={styles.menuButton} onClick={handleMenuButtonClick}>
          {isOpen ? 'Close' : 'Menu'}
        </button>
      </header>
      <Portal id="main-menu" className={styles.portal}>
        <div
          className={cc([styles.drawer, isOpen && styles.isOpen])}
          style={{
            '--primary-color': colors.primaryColor,
            '--secondary-color': colors.secondaryColor,
            '--background-color': colors.bgColor,
          }}
        >
          <div className={styles.container}>
            <nav className={styles.menu}>
              {mainMenu?.menuItems?.nodes?.map((menuItem, i) => {
                const path = menuItem?.connectedObject?.uri ?? menuItem.url;

                const { primaryColor, secondaryColor, bgColor } =
                  menuItem?.connectedObject || {};

                return (
                  <Link
                    key={`${menuItem.url}-${i}`}
                    to={path}
                    className={styles.menuItem}
                    onMouseEnter={() =>
                      handleMouseEnter({
                        primaryColor,
                        secondaryColor,
                        bgColor,
                      })
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    <span>{menuItem.label}</span>
                  </Link>
                );
              })}
            </nav>
            <Newsletter />
          </div>
        </div>
      </Portal>
    </>
  );
}

const MENU_QUERY = graphql`
  {
    mainMenu: wpMenu(slug: { eq: "main-menu" }) {
      name
      menuItems {
        nodes {
          label
          url
          connectedObject {
            ... on WpContentNode {
              uri
            }
            ... on WpPage {
              primaryColor
              secondaryColor
              bgColor
            }
          }
        }
      }
    }
  }
`;
