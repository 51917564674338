import * as React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import parse from 'html-react-parser';
import styles from './postlist.module.scss';

export default function PostListItem({ post }) {
  return (
    <article
      className={styles.postListItem}
      style={{
        color: post.primaryColor,
        '--primary-color': post.primaryColor,
      }}
    >
      <div className={styles.image}>
        {post.featuredImage && (
          <Link to={post.uri} className={styles.link}>
            <Image
              fluid={post.featuredImage?.remoteFile?.childImageSharp?.fluid}
              alt={post.featuredImage?.altText}
            />
          </Link>
        )}
      </div>
      <div className={styles.title}>
        <Link to={post.uri} className={styles.link}>
          {post.title && <h2>{parse(post.title)}</h2>}
        </Link>
      </div>
      {post.excerpt && (
        <div className={styles.excerpt}>
          {parse(post.excerpt.replace('[&hellip;]', ''))}
          <Link to={post.uri}>Read more</Link>
        </div>
      )}
    </article>
  );
}
