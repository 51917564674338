import * as React from 'react';
import PostListItem from './PostListItem';
import styles from './postlist.module.scss';

export default function PostList({ posts }) {
  return (
    <section className={styles.postList}>
      {posts.map(post => (
        <PostListItem key={`${post.id}`} post={post} />
      ))}
    </section>
  );
}
