import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import EventListItem from './EventListItem';
import styles from './eventlist.module.scss';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export default function EventList() {
  const {
    allWpEvent: { events },
  } = useStaticQuery(graphql`
    query events {
      allWpEvent {
        events: nodes {
          id: databaseId
          title
          blocks
          slug
          primaryColor
          eventInfo {
            start
            end
            startTime
            endTime
            location
            ages
          }
          featuredImage {
            remoteFile {
              ...HeroImage
            }
            altText
          }
        }
      }
    }
  `);

  for (const event of events) {
    if (typeof event.blocks === 'string') {
      event.blocks = JSON.parse(event.blocks);
    }
  }

  const displayedEvents = events
    .filter(event => {
      const { start, end } = event.eventInfo;
      const lastDate = end || start;
      if (dayjs(lastDate).isSameOrAfter(dayjs(), 'day')) {
        return true;
      }
      return false;
    })
    ?.sort((a, b) => {
      const { start: as, end: ae } = a.eventInfo;
      const { start: bs, end: be } = b.eventInfo;
      const lastA = dayjs(ae || as);
      const lastB = dayjs(be || bs);
      if (lastA.isSame(lastB, 'day')) {
        // handle hours
        let { startTime: aTime } = a.eventInfo;
        aTime =
          aTime.indexOf('pm') > -1 ? parseInt(aTime) + 12 : parseInt(aTime);
        let { startTime: bTime } = b.eventInfo;
        bTime =
          bTime.indexOf('pm') > -1 ? parseInt(bTime) + 12 : parseInt(bTime);

        return parseInt(aTime) - parseInt(bTime);
      } else if (lastA.isBefore(lastB, 'day')) {
        return -1;
      }
      return 1;
    });

  return (
    <section className={styles.eventList}>
      {displayedEvents.map(event => (
        <EventListItem key={`${event.id}`} event={event} />
      ))}
    </section>
  );
}
