import * as React from 'react';
import Image from 'gatsby-image';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Blocks } from '~/components';
import styles from './eventlist.module.scss';

dayjs.extend(advancedFormat);

export default function EventListItem({ event }) {
  const { title, featuredImage, blocks, slug } = event;
  const { start, end, startTime, endTime, location, ages } =
    event.eventInfo || {};

  let date = formatDate(start, end);
  let time = formatTime(startTime, endTime);

  return (
    <article
      id={slug}
      className={styles.eventListItem}
      style={{
        color: event.primaryColor,
        '--primary-color': event.primaryColor,
      }}
    >
      <div className={styles.image}>
        {featuredImage && (
          <Image
            fluid={featuredImage?.remoteFile?.childImageSharp?.fluid}
            alt={featuredImage?.altText}
          />
        )}
      </div>
      <div className={styles.title}>
        <a href={`#${slug}`} className={styles.anchorLink}>
          {title && <h2>{parse(title)}</h2>}
        </a>
      </div>
      <div className={styles.meta}>
        <div>
          {date && <div className={styles.metaEntry}>{date}</div>}
          {time && <div className={styles.metaEntry}>{time}</div>}
        </div>
        <div>
          {ages && <div className={styles.metaEntry}>ages: {ages}</div>}
          {location && (
            <div className={styles.metaEntry}>location: {location}</div>
          )}
        </div>
      </div>
      <div className={styles.info}>
        <Blocks blocks={blocks} />
      </div>
    </article>
  );
}

function formatDate(start, end) {
  if (!start) return null;

  const startDay = dayjs(start);
  const endDay = dayjs(end);
  const sameDay = startDay.isSame(endDay, 'day');
  const sameMonth = startDay.isSame(endDay, 'month');
  const sameYear = startDay.isSame(endDay, 'year');
  const thisYear = sameYear && startDay.isSame(dayjs(), 'year');
  const yearFormat = thisYear ? '' : ', YYYY';

  if (!end) return startDay.format('dddd MMMM Do' + yearFormat);
  if (sameDay) return startDay.format('dddd MMMM Do' + yearFormat);
  if (sameMonth)
    return `${startDay.format('MMMM Do')} - ${endDay.format(
      'Do' + yearFormat
    )}`;
  if (sameYear)
    return `${startDay.format('MMMM Do')} - ${endDay.format(
      'MMMM Do' + yearFormat
    )}`;

  return `${startDay.format('MMM Do' + yearFormat)} - ${endDay.format(
    'MMM Do' + yearFormat
  )}`;
}

function formatTime(start, end) {
  if (!start) return null;

  let startTime = `${start.replace(' ', '')}`;

  if (!end) return `${startTime} cst`;

  const endTime = `${end.replace(' ', '')}`;

  if (startTime.slice(-2) === endTime.slice(-2))
    startTime = startTime.slice(0, -2);

  return `${startTime} - ${endTime} cst`;
}
