import * as React from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import cc from 'classcat';
import { Carousel, Form, EventList, PostList } from '~/components';
import styles from './blocks.module.scss';

export function BlockContent({ children, isPost, ...restProps }) {
  return (
    <div className={cc([styles.content, isPost && styles.post])} {...restProps}>
      {children}
    </div>
  );
}

/**
 * Blocks: loops through all blocks & nested blocks
 */
export default function Blocks({ blocks }) {
  if (!blocks?.length) return null;

  return blocks.map((block, key) => (
    <Block key={key} {...block}>
      {block.innerBlocks?.length > 0 && <Blocks blocks={block.innerBlocks} />}
    </Block>
  ));
}

/**
 * Block: picks component or returns element
 */
export function Block(props) {
  const {
    WpMedia: { nodes: media },
  } = useStaticQuery(graphql`
    query {
      WpMedia: allWpMediaItem {
        nodes {
          id: databaseId
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const { children, innerHTML, innerContent, blockName, attrs } = props;

  // Return component if block name matches
  const Component = (() => {
    switch (blockName) {
      case 'core/gallery':
        return attrs.isCarousel && Carousel;
      case 'gravityforms/form':
        return Form;
      case 'du/post-list':
        if (attrs.type === 'event') return EventList;
        if (attrs.type === 'post') return PostList;
      default:
        return null;
    }
  })();

  if (Component) return <Component media={media} {...props} />;

  // Else replace Links & Images & Classnames with correcto stuff
  let content = innerHTML.trim();

  // replace where inner content goes with a placeholder tag
  if (children) {
    content = innerContent
      .map(htmlString => {
        if (htmlString === null) return '<replace />';
        return htmlString;
      })
      .join('');
  }
  const reactChildren = React.Children.toArray(children);
  let index = 0;

  // options for html-react-parser
  const options = {
    replace: ({ name, attribs, children }) => {
      if (!attribs) return;

      // if had inner content to replace
      if (reactChildren && name === 'replace') {
        const child = reactChildren[index];
        index += 1;
        return child;
      }

      // if image, use gatsby image if matching local image
      if (name === 'img') {
        if (attribs.class && media?.length > 0) {
          let image;

          const id =
            attribs.class.replace('wp-image-', '') * 1 ||
            attribs['data-id'] * 1;
          if (id) image = media?.find(n => n.id === id);

          if (image) {
            const { class: className } = attribs;
            return (
              <Image
                fluid={image.localFile.childImageSharp.fluid}
                className={className}
              />
            );
          }
        }
      }
      // otherwise add lazy to it.
      if (name === 'img') {
        attribs.loading = 'lazy';
      }

      // if column, add width class
      if (blockName === 'core/column') {
        if (attrs.width) {
          const w = parseInt(attrs.width);
          const spanClass = w > 50 ? 'span-4' : w < 50 ? 'span-2' : 'span-3';
          if (!attribs.class) attribs.style = '';
          attribs.class += ` ${spanClass}`;
        }
      }

      // replace classes with css modules
      if (typeof attribs.class !== 'undefined') {
        attribs.class = attribs.class
          ? cc(attribs.class.split(' ').map(c => styles[c] || c))
          : null;
      }

      // handle links
      if (name === 'a') {
        let { href, class: className, style, ...restProps } = attribs;

        // handle calendly links
        if (href?.indexOf('calendly.com') > -1) {
          if (href.indexOf('?') < 0) {
            href = `${href}?background_color=F5F1E9&text_color=212121&primary_color=D1533B`;
          }
          return (
            <a
              href={href}
              title="register for event"
              onClick={e => {
                e.preventDefault();
                window && window.Calendly.showPopupWidget(href);
                return false;
              }}
              className={className}
              {...restProps}
            >
              {domToReact(children, options)}
            </a>
          );
        }

        const formattedHref = href?.replace(
          /^.*(gokonjo\.com|konjo\.netlify\.app|du\.works)/gi,
          ''
        );
        const isExternal = /\./gi.test(formattedHref);

        // open external links in new tab
        if (href?.indexOf('mailto:') > -1) {
          attribs.target = '_blank';
        } else if (isExternal) {
          // add slashes to external urls missing http
          attribs.target = '_blank';
          if (href?.indexOf('http') < 0) attribs.href = `//${href}`;
        } else {
          // is gatsby page route
          return (
            <Link to={formattedHref} className={className} {...restProps}>
              {domToReact(children, options)}
            </Link>
          );
        }
      }
    },
  };

  return parse(content, options);
}
