import { createPortal } from 'react-dom';

export default function Portal({ id, className, children }) {
  // exit if not client
  if (typeof window === 'undefined') {
    return null;
  }

  let modalRoot = document.getElementById(id);

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    if (modalRoot) {
      modalRoot.setAttribute('id', id);
      modalRoot.classList.add(className);
      document.body.appendChild(modalRoot);
    }
  }

  return createPortal(children, modalRoot);
}
