import React, { Fragment, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import parse from 'html-react-parser';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import styles from './form.module.scss';

const IDLE = 'idle';
const SUBMITTING = 'submitting';
const ERROR = 'error';
const SUCCESS = 'success';

export default function Form({ attrs }) {
  const {
    allGfForm: { gravityForms },
  } = useStaticQuery(
    graphql`
      query formQuery {
        allGfForm {
          gravityForms: nodes {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              description
              descriptionPlacement
              type
              choices
              # content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
              conditionalLogic
              emailConfirmEnabled
              # enableOtherChoice
              inputs {
                label
                id
                name
              }
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    `
  );
  const { register, handleSubmit /*errors*/ } = useForm();
  const [status, setStatus] = useState(IDLE);
  const [msg, setMsg] = useState(null);

  const gravityForm = gravityForms?.find(
    form => +form.formId === +attrs.formId
  );

  if (!gravityForm) return null;

  for (const field of gravityForm.formFields) {
    if (typeof field.choices !== 'string') return;
    const choices = JSON.parse(field.choices);

    if (!field.inputs && choices) {
      const inputs = choices.map((choice, i) => {
        return {
          id: `${field.id}.${i}`,
          name: `${field.id}`,
          label: `${choice.value}`,
        };
      });
      field.inputs = inputs;
    }
  }

  const url = `${process.env.GATSBY_WP_URL}/wp-json/gf/v2/forms/${gravityForm.formId}/submissions`;

  const onSubmit = async formData => {
    setStatus(SUBMITTING);

    if (formData) {
      for (let [key, value] of Object.entries(formData)) {
        if (typeof value === 'object' && value.length) {
          for (let [l, w] of Object.entries(value)) {
            formData[`${key}.${l}`] = `${w}`;
          }
          delete formData[`${key}`];
        }
      }
    }

    // hardcoded mailchimp fields
    const email = formData.input_3;
    const shouldAddToMailchimp = formData.input_5_1 === 'Yes';

    const request = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).catch(err => {
      setStatus(ERROR);
      return err;
    });

    const data = await request.json();

    if (data.status > 202) {
      setStatus(ERROR);
    } else {
      setStatus(SUCCESS);

      shouldAddToMailchimp && addToMailchimp(email);
    }

    setMsg(data.confirmation_message);
  };

  switch (status) {
    case SUCCESS:
      return <div className={styles.form}>{msg && parse(msg)}</div>;

    case ERROR:
      return (
        <div className={styles.form}>
          <p>
            There was an error. Please try again, or email us directly at{' '}
            <a
              href="mailto:hello@gokonjo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@gokonjo.com
            </a>
            .
          </p>
          <button onClick={() => setStatus(IDLE)}>Refresh</button>
        </div>
      );
    default:
      return (
        <form
          className={styles.form}
          id={gravityForm.formId}
          name={gravityForm.title}
          onSubmit={handleSubmit(onSubmit)}
        >
          {gravityForm.formFields?.map(field => (
            <Fragment key={field.id}>
              {field?.inputs?.length > 0 ? (
                <div data-name={field.label} className={styles.group}>
                  <span className={styles.label}>
                    {field.label}
                    {field.isRequired && ' (required)'}
                  </span>
                  {field.inputs
                    .filter(i => i.isHidden !== true)
                    .map((input, index) => {
                      const inputId =
                        field.type === 'radio' ? field.id : input.id;
                      return (
                        <label
                          key={`${inputId}-${index}`}
                          className={styles.choice}
                        >
                          <input
                            id={`input_${inputId}`.replace('.', '_')}
                            name={`input_${inputId}`.replace('.', '_')}
                            type={field.type}
                            value={input.label}
                            ref={register({ required: field.isRequired })}
                          />
                          {input.label}
                        </label>
                      );
                    })}
                </div>
              ) : (
                <label className={styles.group}>
                  <span className={styles.label}>
                    {field.label}
                    {field.isRequired && ' (required)'}
                  </span>
                  {field.type !== 'textarea' ? (
                    <input
                      id={`input_${field.id}`.replace('.', '_')}
                      name={`input_${field.id}`}
                      type={field.type}
                      placeholder={field.placeholder}
                      ref={register({ required: field.isRequired })}
                    />
                  ) : (
                    <textarea
                      id={`input_${field.id}`.replace('.', '_')}
                      name={`input_${field.id}`}
                      placeholder={field.placeholder}
                      ref={register({ required: field.isRequired })}
                    />
                  )}
                </label>
              )}
            </Fragment>
          ))}
          <button type="submit" value="Submit">
            {status === SUBMITTING ? 'Sending' : gravityForm.button.text}
          </button>
        </form>
      );
  }
}
