import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Newsletter } from '~/components';
import styles from './footer.module.scss';

export default function Footer() {
  const { socialMenu } = useStaticQuery(MENU_QUERY);

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.alignOutside}>
          <div className={styles.copyright}>
            <p className={styles.label}>#gokonjo</p>
            <p>
              <span className={styles.c}>©</span>
              {new Date().getFullYear()} Konjo, LLC
            </p>
          </div>
        </div>
        <div>
          <p className={styles.label}>Follow us</p>
          <div>
            {socialMenu?.menuItems?.nodes?.map((menuItem, i) => (
              <Fragment key={`${menuItem.url}-${i}`}>
                {!!i && ', '}
                <a
                  href={menuItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {menuItem.label}
                </a>
              </Fragment>
            ))}
          </div>
        </div>
        <div>
          <Newsletter />
        </div>
      </div>
    </footer>
  );
}

const MENU_QUERY = graphql`
  {
    socialMenu: wpMenu(slug: { eq: "social-menu" }) {
      name
      menuItems {
        nodes {
          label
          url
        }
      }
    }
  }
`;
